import { Circle, Spinner } from "@slashid/react-primitives"
import type { ThemeVars } from "./flow.types"

const sidVars: ThemeVars = { "--sid-color-primary": "#DCDDE5" }

export function Loading() {
  return (
    <div style={sidVars}>
      <Circle variant="primary">
        <Spinner />
      </Circle>
    </div>
  )
}
