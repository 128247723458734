import { clsx } from "clsx"
import { useAppContext } from "../app/app.context"
import { Text } from "../text"
import * as styles from "./card.css"
import type { ThemeVars } from "../flow"

export type Props = {
  children: React.ReactNode
  footer?: React.ReactNode
  header?: React.ReactNode
  className?: string
  style?: ThemeVars
}

function DefaultHeader() {
  const { logo } = useAppContext()

  return (
    <div className={styles.logo}>
      {typeof logo === "string" ? <img src={logo} alt="logo" className={styles.logo} /> : logo}
    </div>
  )
}

function DefaultFooter() {
  return (
    <Text
      t="footer.text"
      variant={{
        size: "xs",
        weight: "semibold",
        color: "placeholder",
      }}
    />
  )
}

export function Card({ children, className, header = <DefaultHeader />, footer = <DefaultFooter />, style }: Props) {
  return (
    <article style={style} className={clsx(styles.card, className)}>
      {header && <header className={styles.header}>{header}</header>}
      <div>{children}</div>
      {footer && <footer className={styles.footer}>{footer}</footer>}
    </article>
  )
}
