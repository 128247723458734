import type { InvalidPasswordSubmittedEvent } from "@slashid/slashid"
import { useEffect, useState } from "react"
import { PasswordInput, Stack, Text, Button, sprinkles, interpolate } from "@slashid/react-primitives"

import * as styles from "./flow.css"
import { useI18n } from "../text/use-i18n"
import { useAppContext } from "../app/app.context"
import type { TranslationKeys } from "../../domain/i18n"

function getValidationI18nKey(errorEvent: InvalidPasswordSubmittedEvent): TranslationKeys {
  const textKey = `recover.password.validation.${errorEvent.failedRules[0].name}`

  // prefer the first error
  return textKey as TranslationKeys
}

function getValidationInterpolationTokens({
  errorEvent,
  password,
}: {
  errorEvent: InvalidPasswordSubmittedEvent
  password: string
}): Record<string, string> {
  const firstRuleToFail = errorEvent.failedRules[0]
  let failingValue = ""

  if (firstRuleToFail.matchType === "must_not_match") {
    const matchResult = firstRuleToFail.regexp.exec(password)
    failingValue = matchResult !== null ? matchResult[0] : ""
  }

  if (!failingValue) {
    return {}
  }

  return {
    ILLEGAL_SEQUENCE: failingValue,
  }
}

function ErrorMessage({ message }: { message: string }) {
  return <span className={styles.errorMessage}>{message}</span>
}

export function Recover() {
  const i18n = useI18n()
  const { sdk } = useAppContext()
  const [password, setPassword] = useState("")
  const [passwordConfirm, setPasswordConfirm] = useState("")
  const [error, setError] = useState<string | null>(null)
  const [uiState, setUiState] = useState<"initial" | "submitting">("initial")

  useEffect(() => {
    const onInvalidPassword = (invalidPasswordEvent: InvalidPasswordSubmittedEvent) => {
      setError(
        interpolate(
          i18n(getValidationI18nKey(invalidPasswordEvent)),
          getValidationInterpolationTokens({ errorEvent: invalidPasswordEvent, password })
        )
      )
      setUiState("initial")
    }

    sdk?.subscribe("invalidPasswordSubmitted", onInvalidPassword)

    return () => {
      sdk?.unsubscribe("invalidPasswordSubmitted", onInvalidPassword)
    }
  }, [i18n, sdk, password, setError])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!sdk) {
      return
    }

    if (!password || password !== passwordConfirm) {
      setError(i18n("recover.password.validation.passwordsDoNotMatch"))
      return
    }

    sdk.publish("passwordSubmitted", password)
    setUiState("submitting")
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
    setError(null)
  }

  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordConfirm(event.target.value)
    setError(null)
  }

  return (
    <>
      <Stack space="0.25">
        <Text as="h1" variant={{ size: "2xl-title", weight: "bold" }} t="recover.password.title" />
        <Text variant={{ color: "contrast", weight: "semibold" }} as="h2" t="recover.password.details" />
      </Stack>
      <form onSubmit={handleSubmit}>
        <div className={styles.formInputs}>
          <PasswordInput
            id="password-input"
            label={i18n("recover.password.input.password.label")}
            placeholder={i18n("recover.password.input.placeholder")}
            name="password"
            value={password ?? ""}
            onChange={handlePasswordChange}
            error={Boolean(error)}
            autoComplete="new-password"
          />
          <PasswordInput
            id="password-input-confirm"
            label={i18n("recover.password.input.confirmPassword.label")}
            placeholder={i18n("recover.password.input.placeholder")}
            name="passwordConfirm"
            value={passwordConfirm ?? ""}
            onChange={handleConfirmPasswordChange}
            className={sprinkles({ marginTop: "4" })}
            error={Boolean(error)}
            autoComplete="new-password"
          />
          {error && <ErrorMessage message={error} />}
        </div>

        <Button
          type="submit"
          variant="primary"
          testId="sid-form-initial-submit-button"
          disabled={uiState === "submitting"}
          loading={uiState === "submitting"}
        >
          {i18n("recover.password.submit")}
        </Button>
      </form>
    </>
  )
}
