// based on RFC 5646
export type Language = "en" | "ja"

/**
 * Check the language based on the browser settings.
 * Default to "en"
 */
export function detectLanguage(): Language {
  const language = navigator.language.toLowerCase()
  if (language === "ja" || language.startsWith("ja-")) {
    return "ja"
  }

  return "en"
}

export const defaultStrings = {
  "footer.text": "Top-tier security by SlashID",
  "initial.title": "Logging you in...",
  "initial.details": "Please follow the on-screen instructions.",
  "recover.password.title": "Reset password",
  "recover.password.details": "Enter your new password.",
  "recover.password.input.password.label": "Password",
  "recover.password.input.confirmPassword.label": "Confirm password",
  "recover.password.input.placeholder": "Type your new password",
  "recover.password.submit": "Reset password",
  "recover.password.validation.passwordsDoNotMatch": "Passwords do not match",
  "recover.password.validation.length": "8-256 characters required",
  "recover.password.validation.password_variants": "Contains word 'password'",
  "recover.password.validation.admin_variants": "Contains word 'admin'",
  "recover.password.validation.user_variants": "Contains word 'user'",
  "recover.password.validation.alphanumeric_sequences_1": "Illegal sequence: {{ILLEGAL_SEQUENCE}}",
  "recover.password.validation.alphanumeric_sequences_2": "Illegal sequence: {{ILLEGAL_SEQUENCE}}",
  "recover.password.validation.numeric_sequences_ascending": "Illegal sequence: {{ILLEGAL_SEQUENCE}}",
  "recover.password.validation.numeric_subsequences_ascending": "Illegal sequence: {{ILLEGAL_SEQUENCE}}",
  "recover.password.validation.numeric_sequences_descending": "Illegal sequence: {{ILLEGAL_SEQUENCE}}",
  "recover.password.validation.numeric_subsequences_descending": "Illegal sequence: {{ILLEGAL_SEQUENCE}}",
  "recover.password.validation.common_password_xkcd": "Common password",
  "success.title": "All done!",
  "success.details.default": "Close this page and return to the website.",
  "success.details.autoclose": "Return to the website, this page will close in a moment...",
  "error.title": "Something went wrong...",
  "error.title.authentication_expired": "Magic link expired",
  "error.title.registration_already_completed": "Magic link expired",
  "error.detail": "Return to the form and log in again.",
  "error.detail.authentication_expired": "Return to the form and log in again to continue.",
  "error.detail.registration_already_completed": "Return to the form and log in again to continue.",
}

export type TranslationKeys = keyof typeof defaultStrings
export type Translations = {
  [key in Language]: {
    [key in TranslationKeys]: string
  }
}

// TODO add translations for password validation errors
export const I18N: Translations = {
  en: defaultStrings,
  ja: {
    "footer.text": defaultStrings["footer.text"],
    "initial.title": "ログイン処理を行っています。",
    "initial.details": "処理完了後、画面は自動で切り替わります。",
    "success.title": "認証が完了しました！",
    "success.details.default": "このページを閉じて、元のページへ戻ってください。",
    "success.details.autoclose": defaultStrings["success.details.autoclose"],
    "recover.password.title": defaultStrings["recover.password.title"],
    "recover.password.details": defaultStrings["recover.password.details"],
    "recover.password.input.password.label": defaultStrings["recover.password.input.password.label"],
    "recover.password.input.confirmPassword.label": defaultStrings["recover.password.input.confirmPassword.label"],
    "recover.password.input.placeholder": defaultStrings["recover.password.input.placeholder"],
    "recover.password.submit": defaultStrings["recover.password.submit"],
    "recover.password.validation.passwordsDoNotMatch":
      defaultStrings["recover.password.validation.passwordsDoNotMatch"],
    "recover.password.validation.length": defaultStrings["recover.password.validation.length"],
    "recover.password.validation.password_variants": defaultStrings["recover.password.validation.password_variants"],
    "recover.password.validation.admin_variants": defaultStrings["recover.password.validation.admin_variants"],
    "recover.password.validation.user_variants": defaultStrings["recover.password.validation.user_variants"],
    "recover.password.validation.alphanumeric_sequences_1":
      defaultStrings["recover.password.validation.alphanumeric_sequences_1"],
    "recover.password.validation.alphanumeric_sequences_2":
      defaultStrings["recover.password.validation.alphanumeric_sequences_2"],
    "recover.password.validation.numeric_sequences_ascending":
      defaultStrings["recover.password.validation.numeric_sequences_ascending"],
    "recover.password.validation.numeric_subsequences_ascending":
      defaultStrings["recover.password.validation.numeric_subsequences_ascending"],
    "recover.password.validation.numeric_sequences_descending":
      defaultStrings["recover.password.validation.numeric_sequences_descending"],
    "recover.password.validation.numeric_subsequences_descending":
      defaultStrings["recover.password.validation.numeric_subsequences_descending"],
    "recover.password.validation.common_password_xkcd":
      defaultStrings["recover.password.validation.common_password_xkcd"],
    "error.title": defaultStrings["error.title"],
    "error.title.authentication_expired": defaultStrings["error.title.authentication_expired"],
    "error.title.registration_already_completed": defaultStrings["error.title.registration_already_completed"],
    "error.detail": "再度ログインをしてください。",
    "error.detail.authentication_expired": defaultStrings["error.detail.authentication_expired"],
    "error.detail.registration_already_completed": defaultStrings["error.detail.registration_already_completed"],
  },
} as const

export type TranslationKey = keyof Translations[Language]

export type Translate = (key: TranslationKey) => (typeof I18N)[Language][TranslationKey]

export const createI18n = (language: Language) => (key: TranslationKey) => I18N[language][key]
