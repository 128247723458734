import { Errors } from "@slashid/slashid"
import type { ErrorCode, ErrorState } from "."

export const errorToErrorCode = async (state: ErrorState): Promise<ErrorCode | undefined> => {
  if (await Errors.isPersonExistsError(state.error)) {
    return "registration_already_completed"
  }

  if (await Errors.isTimeoutError(state.error)) {
    return "authentication_expired"
  }

  return undefined
}
