import { Errors } from "@slashid/slashid"
import * as Sentry from "@sentry/react"
import { config } from "../config"

export async function logError(err: unknown, context?: Record<string, string>) {
  const error = ensureError(err)

  if (!config.sentryEnabled) {
    console.error(error)
    if (context) {
      console.log(`Error context: ${JSON.stringify(context)}`)
    }
    return
  }

  Sentry.withScope((scope) => {
    scope.setExtras({ ...context, message: error.message, ...(Errors.isSlashIDError(error) && { ...error.context }) })
    Sentry.captureException(error)
  })
}

export function ensureError(value: unknown): Error {
  if (value instanceof Error) return value

  let stringified = "[Unable to stringify the thrown value]"
  try {
    stringified = JSON.stringify(value)
  } catch {
    // ignore
  }

  const error = new Error(`This value was thrown as is, not through an Error: ${stringified}`)
  return error
}
