import { SlashID, type SlashIDOptions } from "@slashid/slashid"
import * as Sentry from "@sentry/react"
import { Logo, TextProvider, ThemeRoot, isBrowser } from "@slashid/react-primitives"

import {
  AppContext,
  initialAppContextState,
  type AppState,
  type AppContextState,
  type AppReadyState,
} from "./app.context"
import { useEffect, useMemo, useRef, useState } from "react"
import { I18N, detectLanguage } from "../../domain/i18n"
import { Flow, Error } from "../flow"
import { Card } from "../card"

import * as styles from "./app.css"
import { config } from "../../config"

export type Environment = SlashIDOptions["environment"]

export type VerificationProps = {
  environment?: Environment
}

export function Verification({ environment = "production" }: VerificationProps) {
  const isClientSide = isBrowser()
  const [appState, setAppState] = useState<AppState>("initial")
  const [appLogo, setAppLogo] = useState<string | React.ReactNode>(<Logo />)
  const sidRef = useRef<SlashID | undefined>(undefined)

  useEffect(() => {
    if (appState === "initial") {
      if (config.sentryEnabled) {
        Sentry.init({
          dsn: config.sentryDSN,
          environment: config.environment,
        })
      }

      sidRef.current = new SlashID({
        analyticsEnabled: false,
        environment,
      })

      setAppState("ready")
    }
  }, [appState])

  const state: AppContextState = useMemo(() => {
    // checking for hydration makes the first client side render the same as the one on the server side
    // language check and URL params can only be done on the client side so we need to wait for the first render
    if (!isClientSide || appState == "initial") {
      return initialAppContextState
    }

    const appReadyState: AppReadyState = {
      logo: appLogo,
      language: detectLanguage(),
      state: appState,
      sdk: sidRef.current as SlashID,
      setLogo: setAppLogo,
    }

    return appReadyState
  }, [appState, isClientSide, appLogo])

  return (
    <ThemeRoot theme="light">
      <Sentry.ErrorBoundary
        fallback={
          <Card>
            <Error type="error" />
          </Card>
        }
      >
        <AppContext.Provider value={state}>
          <TextProvider text={I18N[state.language]}>
            <main className={styles.app}>
              <Flow />
            </main>
          </TextProvider>
        </AppContext.Provider>
      </Sentry.ErrorBoundary>
    </ThemeRoot>
  )
}

export function App() {
  const environment: Environment = { baseURL: config.baseURL, sdkURL: config.sdkURL }
  return <Verification environment={environment} />
}
