import { Circle, Exclamation, Stack } from "@slashid/react-primitives"
import { Text } from "../text"
import type { ErrorCode, ThemeVars } from "./flow.types"
import type { TranslationKeys } from "../../domain/i18n"

const neutralPrimaryColor: ThemeVars = { "--sid-color-primary": "#DCDDE5" }

export type ErrorType = "warning" | "error" | "neutral"

function ErrorIcon({ type }: { type: ErrorType }) {
  if (type === "neutral") {
    return (
      <div style={neutralPrimaryColor}>
        <Circle variant="primary" shouldAnimate={false}>
          <Exclamation />
        </Circle>
      </div>
    )
  }

  return (
    <Circle variant={type === "warning" ? "blue" : "red"} shouldAnimate={false}>
      <Exclamation />
    </Circle>
  )
}

const errorTypeToTitle = (type?: ErrorCode): TranslationKeys => {
  switch (type) {
    case "authentication_expired":
      return "error.title.authentication_expired"
    case "registration_already_completed":
      return "error.title.registration_already_completed"
  }

  return "error.title"
}

const errorTypeToDescription = (type?: ErrorCode): TranslationKeys => {
  switch (type) {
    case "authentication_expired":
      return "error.detail.authentication_expired"
    case "registration_already_completed":
      return "error.detail.registration_already_completed"
  }

  return "error.detail"
}

export function Error({ errorCode, type, redirectUri }: { errorCode?: ErrorCode, type: ErrorType; redirectUri?: string }) {
  if (redirectUri) {
    window.location.replace(redirectUri)
  }

  const title = errorTypeToTitle(errorCode)
  const description = errorTypeToDescription(errorCode)

  return (
    <>
      <Stack space="0.25">
        <Text as="h1" t={title} variant={{ size: "2xl-title", weight: "bold" }} />
        <Text as="h2" t={description} variant={{ color: "contrast", weight: "semibold" }} />
      </Stack>
      <ErrorIcon type={type} />
    </>
  )
}
