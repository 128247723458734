import { useCallback, useEffect, useMemo, useState } from "react"
import { Customisation } from "@slashid/react"
import type { ChallengeListInner, RedirectUriDiscoveredEvent, UICustomizationChallenge } from "@slashid/slashid"

import { Card } from "../card"
import { Error } from "./flow.error"
import type { Challenges, State, FlowType, SlashIDUIConfig, ThemeVars, ErrorCode } from "./flow.types"
import { useAppContext } from "../app/app.context"
import { Progress } from "./flow.progress"
import { Success } from "./flow.success"
import { logError } from "../../domain/errors"
import { Loading } from "./flow.loading"
import { errorToErrorCode } from "./flow.util"

function isPasswordRecoveryFlow(challenges: Challenges): boolean {
  return challenges.some((challenge) => challenge.type === "password_reset")
}

function getCustomisationChallenge(challenges: Challenges): UICustomizationChallenge | undefined {
  const challenge = challenges.find((challenge) => challenge.type === "ui_customization")

  if (challenge) {
    return challenge as UICustomizationChallenge
  } else return undefined
}

function getCustomisationConfig(challenge: UICustomizationChallenge): SlashIDUIConfig {
  // we already have all the info in the challenge
  return challenge.options?.authn_redirect_page_ui_config as SlashIDUIConfig
}

function getFlowTypeFromChallenges(challenges: Challenges): FlowType {
  if (isPasswordRecoveryFlow(challenges)) {
    return "password-recovery"
  }

  return "catch-all"
}

function loadCustomFonts(themeVars: ThemeVars) {
  if (!themeVars || !themeVars["--sid-font-family"]) return

  const fontImports = Customisation.getGoogleFontImports(themeVars["--sid-font-family"] as string)
  if (!fontImports.length) return

  document.head.insertAdjacentHTML("beforeend", fontImports.join(""))
}

export function Flow() {
  const [flowState, setFlowState] = useState<State>({ state: "initial" })
  // TODO use a better type definition
  const [uiCustomisation, setUiCustomisation] = useState<undefined | SlashIDUIConfig>(undefined)
  const { sdk, state: appState, setLogo } = useAppContext()

  useEffect(() => {
    if (flowState.state !== "initial") return

    async function processChallenges() {
      if (!sdk) return
      setFlowState({ state: "parsing-url" })

      let challenges: ChallengeListInner[] | null = []

      try {
        challenges = await sdk.getChallengesFromURL()
      } catch (e: unknown) {
        logError(e)
      }

      if (!challenges || challenges.length === 0) {
        setFlowState({ state: "no-challenges", challenges: null })
        return
      }

      const customisationChallenge = getCustomisationChallenge(challenges)
      if (customisationChallenge) {
        const customisationConfig = getCustomisationConfig(customisationChallenge)

        if (customisationConfig) {
          loadCustomFonts(customisationConfig.themeVars)
          setUiCustomisation(customisationConfig)
          if (customisationConfig.logoUrl) {
            setLogo(customisationConfig.logoUrl)
          }
        }
      }

      const flowType = getFlowTypeFromChallenges(challenges)
      setFlowState({ state: "progress", challenges, flowType })
    }

    processChallenges()
  }, [appState, flowState.state, sdk])

  const handleSuccess = useCallback(() => {
    if (flowState.state !== "progress") return

    setFlowState({
      state: "success",
      challenges: flowState.challenges,
      flowType: flowState.flowType,
    })
  }, [flowState])

  const handleError = useCallback(
    ({ error }: { error: Error }) => {
      if (flowState.state !== "progress") return

      setFlowState({
        state: "error",
        challenges: flowState.challenges,
        flowType: flowState.flowType,
        error,
      })
    },
    [flowState]
  )

  const [redirectUri, setRedirectUri] = useState<undefined | string>(undefined)
  const handleRedirectDiscovered = (event: RedirectUriDiscoveredEvent) => {
    setRedirectUri(event.redirectUri)
  }

  const [errorCode, setErrorCode] = useState<ErrorCode | null | undefined>(null)
  useEffect(() => {
    ;(async () => {
      if (appState !== "ready") return

      switch (flowState.state) {
        case "no-challenges":
        case "progress":
        case "success": {
          setErrorCode(undefined)
          break
        }

        case "error": {
          setErrorCode(await errorToErrorCode(flowState))
          break
        }
      }
    })()
  }, [appState, flowState.state])
  const errorCodeResolved = errorCode !== null
  const inTerminalState = useMemo(() => ["success", "error"].includes(flowState.state), [flowState.state])
  const inTerminalStateButErrorUnresolved = inTerminalState && !errorCodeResolved

  const Container = useCallback(
    ({ children = <></> }: { children?: React.ReactNode }) => (
      <Card
        style={{ ...(uiCustomisation?.themeVars && uiCustomisation.themeVars) }}
        {...{ ...(uiCustomisation?.showBadge === false && { footer: <></> }) }}
      >
        {children}
      </Card>
    ),
    [uiCustomisation]
  )

  if (appState !== "ready" || flowState.state === "initial") {
    return <Loading />
  }

  if (flowState.state === "no-challenges") {
    return (
      <div style={{ textAlign: "center" }}>
        <Error type="neutral" />
      </div>
    )
  }

  if (flowState.state === "parsing-url") return <Loading />

  if (flowState.state === "progress" || inTerminalStateButErrorUnresolved) {
    return (
      <Container>
        <Progress
          onSuccess={handleSuccess}
          onError={handleError}
          flowType={flowState.flowType}
          onRedirectDiscovered={handleRedirectDiscovered}
        />
      </Container>
    )
  }

  if (flowState.state === "success") {
    return (
      <Container>
        <Success redirectUri={redirectUri} autoClose={uiCustomisation?.autoClose} />
      </Container>
    )
  }

  if (flowState.state === "error" && errorCodeResolved) {
    return (
      <Container>
        <Error type="error" errorCode={errorCode} redirectUri={redirectUri} />
      </Container>
    )
  }

  return <Container />
}
